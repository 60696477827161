@tailwind base;
@tailwind components;
@tailwind utilities;


.mdc-notched-outline__notch {
  border-right: none !important;
}

.mat-mdc-input-element {
  box-shadow: none !important;
}

/* You can add global styles to this file, and also import other style files */

body {
  background-color: whitesmoke;
  color: rgb(32, 74, 96);
  font-family: 'Open Sans';
}

.mat-mdc-radio-button~.mat-radio-button {
  margin-left: 16px;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
mat-radio-group {
  white-space: nowrap;
}

.reg-form-card {
  max-width: 900px;
  margin: auto;
  padding: 1em;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: white;
}

.reg-form-card-content {
  padding-right: 1.5em;
  padding-left: 1.5em;
}

.card {
  max-width: 900px;
  margin: auto;
  height: 94vh;
  padding: 1em;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: white;
}

.card-content {
  overflow-y: auto;
  padding-right: 1.5em;
  padding-left: 1.5em;


}

.alert div {
  background-color: #fed3d3;
  color: #820000;
  padding: 1rem;
  margin-bottom: 1rem;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background: white;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #1e1647;
}

.spacer {
  flex: 1 1 auto;
}